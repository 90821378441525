import { Prisma } from "@prisma/client";
import { json } from "@remix-run/node";
import { prisma } from "~/clients/prisma.server";
import { handleError } from "~/entry.server";
import { formatDateForDB } from "~/repo/utils";

export const getBookableRetreats = async () => {
  return prisma.retreat.findMany({
    where: bookableFilter(),
    orderBy: { start_date: "asc" },
  });
};

export const getBookableRetreatBySlug = async (slug: string) => {
  return prisma.retreat.findUnique({
    where: {
      ...bookableFilter(),
      slug: slug,
    },
    include: {
      prices: { where: { archived: false }, orderBy: { cents: "desc" } },
    },
  });
};

export const getAllRetreats = async () => {
  return prisma.retreat.findMany({
    orderBy: { start_date: "asc" },
    include: {
      prices: { where: { archived: false }, orderBy: { cents: "desc" } },
    },
  });
};

export const getRetreatBySlug = async (slug: string) => {
  return prisma.retreat.findUnique({
    where: { slug: slug! },
    include: {
      prices: { where: { archived: false }, orderBy: { cents: "desc" } },
    },
  });
};

export const getRetreatById = async (
  id: string,
  {
    includeArchivedPrices = false,
    includeBookings = false,
  }: { includeArchivedPrices?: boolean; includeBookings?: boolean } = {},
) => {
  const priceFilter = includeArchivedPrices
    ? {}
    : { where: { archived: false } };
  return prisma.retreat.findUnique({
    where: { id: id! },
    include: {
      prices: { ...priceFilter, orderBy: { cents: "desc" } },
      bookings: includeBookings && {
        orderBy: { created_at: "desc" },
        include: {
          price: true,
        },
      },
    },
  });
};

const bookableFilter = () => ({
  end_date: { gte: formatDateForDB(new Date()) },
  published: true,
  booking_open: true,
});

export const createOrUpdateRetreatResponse = async (
  promise: Promise<any>,
  request: Request,
) => {
  try {
    await promise;
  } catch (e) {
    const errors = [];
    const global_errors = [];

    if (e instanceof Prisma.PrismaClientKnownRequestError) {
      if (
        e.message.includes("Unique constraint failed on the fields: (`slug`)")
      ) {
        errors.push({
          path: ["slug"],
          message: "This slug is already taken",
        });
      }
    }

    if (errors.length == 0 && global_errors.length == 0) {
      console.log(e);
      handleError(e, { request });
      global_errors.push("Unknown error");
    }
    return json(
      {
        errors,
        global_errors,
      },
      { status: 422 },
    );
  }
  return null;
};
