import { format } from "date-fns-tz";

export const formatDateForDB = (date: Date) => {
  return format(date, "yyyy-MM-dd") + "T00:00:00Z";
};

export const formatRetreatForDB = (retreat: any) => {
  return {
    ...retreat,
    start_date: formatDateForDB(retreat.start_date),
    end_date: formatDateForDB(retreat.end_date),
  };
};
